import request from '@/utils/request'
// 直播列表
export function getLiveListPage(params) {
    return request({
        url: '/aitp-master/portal/starviewLive/getListPage',
         mtf: 'et',
        params: params
    })
}
// 回播列表
export function getReLiveListPage(params) {
    return request({
        url: '/aitp-master/portal/starviewReplay/getListPage',
         mtf: 'et',
        params: params
    })
}
// 回播详情
export function getReLiveDetailById(params) {
    return request({
        url: '/aitp-master/portal/starviewReplay/getById',
         mtf: 'et',
        params: params
    })
}
// 回播评论列表
export function getListComment(params) {
    return request({
        url: '/aitp-master/portal/starviewReplay/listComment',
         mtf: 'et',
        params: params
    })
}
// 回播新增评论
export function addComment(params) {
    return request({
        url: '/aitp-master/portal/starviewReplay/addComment',
        mtf: 'st',
        data: params
    })
}
// 回播分类
export function selectReplayTypeList(params) {
    return request({
        url: '/aitp-master/common/selectReplayTypeList',
         mtf: 'et',
        params: params
    })
}
// 直播列表
export function getListPage(params) {
    return request({
        url: '/aitp-master/portal/starviewLive/getListPage',
         mtf: 'et',
        params: params
    })
}
// 获取百家云直播地址
export function getBaijiayunUrl(params) {
    return request({
        url: '/aitp-master/portal/starviewLive/getBaijiayunUrl',
         mtf: 'et',
        params: params
    })
}
// 获取保利威直播地址
export function getPolyvUrl(params) {
    return request({
        url: '/aitp-master/portal/starviewLive/getPolyvUrl',
         mtf: 'et',
        params: params
    })
}



// 回播评论点赞
export function giveCommentThumbs(params) {
    return request({
        url: '/aitp-master/portal/starviewReplay/giveCommentThumbs',
        mtf: 'st',
        data: params
    })
}
// 回播评论点赞
export function giveThumbs(params) {
    return request({
        url: '/aitp-master/portal/starviewReplay/giveThumbs',
        mtf: 'st',
        data: params
    })
}

// 回播观看完成
export function addRecord(params) {
    return request({
        url: '/aitp-master/portal/starviewReplay/addRecord',
         mtf: 'st',
        data: params
    })
}
// 当寻 直播时间安排
export function getListByMonth(params) {
    return request({
        url: '/aitp-master/portal/starviewLive/getListByMonth',
         mtf: 'et',
         params: params
    })
}






