import {login, getInfo, getMenuList, logout, refreshToken, unreadCount, getHomePersonalInfo} from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { setLoginTime } from '@/utils/storage'

const user = {
  state: {
    token: getToken(),
    unread:0,
    userInfo: JSON.parse(localStorage.getItem("userInfo"))|| {},
    personInfo: JSON.parse(localStorage.getItem("personInfo"))|| {},
    menu:[],
    courseType:{
      courseClassId:'',
      subCourseClassId:''
    },
    postCourseType:{
      courseClassId:'',
      subCourseClassId:''
    },
    searchValue:'',
    trackData:''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;

    },
    SET_TRACK: (state, data) => {
      state.trackData = data;

    },
    SET_SEARCH: (state, val) => {
      state.token = val;

    },
    SET_USERINFO: (state, userinfo) => {

      state.userInfo = userinfo;
      //cookie  2小时过期
      // let seconds = 3600*2;
      // let expires = new Date(new Date() * 1 + seconds * 1000);
      // Cookies.set('userInfo', JSON.stringify(userinfo),{ expires: expires})
      localStorage.setItem('userInfo',JSON.stringify(userinfo))
    },
    SET_PERSONINFO: (state, personinfo) => {

      state.personInfo = personinfo;
      localStorage.setItem('personInfo',JSON.stringify(personinfo))
    },
    SET_MENU:(state,menu)=>{
      state.menu = menu;
    },
    SET_UNDERREAD:(state,num)=>{

      state.unread = num;
    },
    SET_COURSE_TYPE:(state,data)=>{
      state.courseType = data;
    },
    SET_POSTCOURSE_TYPE:(state,data)=>{
      state.postCourseType = data;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then(response => {

            const data = response.data;
            const tokenStr = data.tokenHead + data.token;
            setToken(tokenStr,data.token);
            commit('SET_TOKEN', tokenStr);
            setLoginTime(String(new Date().getTime()));
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetMenuList({ commit }, {}) {

      return new Promise((resolve, reject) => {
        getMenuList({})
          .then(response => {

            var temp = [];
            response.data.map(item =>{
                if(item.hidden == 0){
                  var children = []
                  item.children.map(v=>{
                      if(v.hidden == 0){
                         // 限定培训部+系统管理员 可以看到 bi
                        if(v.component.replace("/","") == 'satisfactionSurveyBoard'){
                          var name = user.state.userInfo.name;
                          if(name=="苏允迎" || name=="宣峰" ||name=="张潇翔"||name=="李秀"||name=="刘舒畅" ||user.state.userInfo.role === 1){
                            children.push(v)
                          }
                        }else{
                          children.push(v)
                        }
                      }
                  })
                  item.children = children;
                  temp.push(item);
                }
            })
            commit('SET_MENU',  temp);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    MODIFYTOKEN({ commit }, token) {
      setToken(token);
      commit('SET_TOKEN', token);
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then(response => {
            const data = response.data;
            commit('SET_USERINFO', data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 获取用户徽章等信息
    GetPersonInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getHomePersonalInfo()
          .then(response => {
            const data = response.data;
            commit('SET_PERSONINFO', data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetUnRead({ commit, state }) {
      return new Promise((resolve, reject) => {
        unreadCount({id:user.state.userInfo.id}).then((res) => {

                if (res.code == 200) {
                  commit('SET_UNDERREAD', res.data.unreadCount);
                  resolve(res);
                }

        });

      });
    },
    SetSelectType({ commit }, data) {

      commit('SET_COURSE_TYPE', data);
    },
    SetPostCourseType({ commit }, data) {

      commit('SET_POSTCOURSE_TYPE', data);
    },
    SetTrack({ commit }, data) {

      commit('SET_TRACK', data);
    },
    getSearchValue({ commit }, val) {

      commit('SET_SEARCH', val);
    },

    // 获取未读数量
    // GetUnRead({ commit, state }) {

    //   return new Promise((resolve, reject) => {
    //     alert(user.userInfo.id)
    //     unreadCount({ id: user.userInfo.id }).then((res) => {

    //       if (res.code == 200) {
    //         commit('SET_UNDERREAD', res.data.unreadCount);
    //         resolve(response);
    //       }
    //     }).catch(error => {
    //       alert(123111)
    //       reject(error);
    //     });
    //   });
    // },
    // 登出
    LogOut({ commit, state }) {

      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_USERINFO', {});
            removeToken();

            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    RESETTOKEN({ commit }) {
      refreshToken({client: 'pc', refreshToken: localStorage.getItem('refreshToken')}).then(res => {
        if (res.code == 200) {
          const tokenStr = res.data.tokenHead + res.data.token;
          setToken(tokenStr);
          localStorage.setItem('refreshToken', res.data.refreshToken)
          setLoginTime(String(new Date().getTime()));
          commit('SET_TOKEN', tokenStr);
        }
      })
    },
    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        commit('SET_USERINFO', {});
        removeToken();
        resolve();
      });
    }
  }
};

export default user;
