<template>
  <div id="index" class="wraper all">
    <section class="seciton1">
      <div class="left-box">
        <div class="user-card-wraper">
          <div class="user-info">
            <div class="avatar-bg">
              <img src="../assets/index/bj.png" alt="" />
            </div>
            <div class="avatar-box">
              <div class="avatar">
                <img class="a-photo" :src="userInfo.photo" alt="" />
                <router-link to="/allHonor">
                  <img
                    class="a-icon"
                    v-if="personInfo.chengzhangLightPicUrl"
                    :src="personInfo.chengzhangLightPicUrl"
                    alt=""
                  />
                </router-link>
                <router-link to="/allHonor">
                  <img
                    class="a-icon"
                    v-if="personInfo.chengjiuLightPicUrl"
                    :src="personInfo.chengjiuLightPicUrl"
                    alt=""
                  />
                </router-link>
                <router-link to="/allHonor">
                  <img
                    class="a-icon"
                    v-if="personInfo.renwuLightPicUrl"
                    :src="personInfo.renwuLightPicUrl"
                    alt=""
                  />
                </router-link>
              </div>
              <div class="user-name">
                <span>Hello</span> {{ userInfo.name }}
                <span v-if="personInfo.medalName" class="level">{{
                  personInfo.medalName
                }}</span>
              </div>
              <div class="date">{{ todayWeek }} {{ nowDate }}</div>
            </div>
            <div class="user-info-box">
              <div class="info-box">
                <div class="t">学习时长（小时）</div>
                <router-link to="/myHonor" class="d">{{
                  personInfo.totalTimeHour
                }}</router-link>
                <!-- <div class="con">
                  昨日提升
                  <img src="../assets/index/up.png" alt="" />
                  <span>2</span>
                </div> -->
              </div>
              <div class="info-box">
                <div class="t">我的积分</div>
                <router-link to="/myHonor" class="d">{{
                  personInfo.integral
                }}</router-link>
                <!-- <div class="con">
                                   昨日提升
                  <img src="../assets/index/up.png" alt="" />
                  <span>2</span>
                </div> -->
              </div>
              <div class="info-box">
                <div class="t">完成课程数</div>
                <router-link to="/course?type=3" class="d">{{
                  personInfo.completeCourseNum
                }}</router-link>
                <!-- <div class="con">
                                   昨日提升
                  <img src="../assets/index/up.png" alt="" />
                  <span>2</span>
                </div> -->
              </div>
              <div class="info-box">
                <div class="t">待办任务数</div>
                <router-link to="/growthRoom" class="d">{{
                  personInfo.taskCount
                }}</router-link>
              </div>
              <div class="info-box">
                <div class="t">我的消息</div>
                <router-link to="/persionCenter?activeIndex=4" class="d">{{
                  messageCount
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="section-box">
          <div class="box" @click="jumpRoad">
            <div class="box-head">
              <div class="t">成长之路</div>
              <img src="../assets/index/y.png" alt="" />
            </div>
            <div class="content-box">
              <div class="bg">
                <img src="../assets/index/road-bg.png" alt="" />
              </div>
              <div class="content">
                <div class="t">基础阶段</div>
                <div class="d">品牌模型-WBT素材</div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="box-head" @click="jumpLiveList">
              <div class="t">星享视界</div>
              <img src="../assets/index/y.png" alt="" />
            </div>
            <div class="content-box" @click="jumpLive(dataInfo.liveList[0], 1)">
              <div class="bg">
                <img :src="dataInfo.liveList[0]?.pic" alt="" />
              </div>
              <div class="reply-box">
<!--                <img src="../assets/index/live-bg.png" alt="" />-->
                <div class="reply-t">
<!--                  <img src="../assets/index/live.png" alt="" class="live" />-->
                  <img  class="live-status" v-if="dataInfo.liveList[0]?.viewStatus == 1" src="../assets/live/live-status-1.png" alt="">
                  <img  class="live-status" v-if="dataInfo.liveList[0]?.viewStatus == 2" src="../assets/live/live-status-2.png" alt="">
                  <img  class="live-status" v-if="dataInfo.liveList[0]?.viewStatus == 3" src="../assets/live/live-status-3.png" alt="">
<!--                  <div class="t">
                    {{
                      dataInfo.liveType == "replay"
                        ? "回播"
                        : dataInfo.liveType == "live"
                        ? "正在直播"
                        : ""
                    }}
                  </div>-->
                </div>
              </div>
              <div class="content">
                <div class="t">{{ dataInfo.liveList[0].name }}</div>
                <div class="d-box">
                  <div class="date">{{ dataInfo.liveList[0].starttime }}</div>
                  <div class="see-box" v-if="dataInfo.liveType == 'replay'">
                    <img src="../assets/index/see.png" alt="" />
                    <div class="num">{{ dataInfo.liveList[0].number }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="box-head" @click="moreHistory">
              <div class="t">学习轨迹</div>
              <img src="../assets/index/y.png" alt="" />
            </div>
            <div class="content">
              <div
                class="h-item"
                v-for="(item, index) in locusList"
                :key="index"
                @click="enterDetail(item)"
              >
                <div class="t">
                  【{{
                    item.type == 1 ? "课程" : item.type == 2 ? "测试" : "社区"
                  }}】{{ item.name }}
                </div>
                <div v-if="item.type == 1" class="d">
                  学习{{ item.lessonTime }}分钟 进度{{ item.percent }}%
                </div>
                <div class="d" v-if="item.type == 2">
                  测试完成 成绩为{{ item.score }}
                </div>
                <div class="d" v-if="item.type == 3 || item.type == 4">
                  <span>
                    <img
                      class="icon"
                      src="@/assets/common/icon/icon-comment.png"
                      alt=""
                    />
                    <span>{{ item.readNumber || 0 }}</span>
                  </span>
                  <span>
                    <img
                      class="icon"
                      src="@/assets/common/icon/icon-view.png"
                      alt=""
                    />
                    <span>{{ item.commentNumber || 0 }}</span>
                  </span>
                  <span v-if="item.type == 4">
                    <img
                      class="icon"
                      src="@/assets/common/icon/icon-thumbs.png"
                      alt=""
                    />
                    <span>{{ item.thumbsNumber || 0 }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-box">
        <div class="head-box">
          <img src="../assets/index/score.png" alt="" />
          <div class="t">全国榜</div>
        </div>
        <div class="content">
          <div class="box">
            <div style="flex: 1; display: flex;align-items: center;">
              <span class="rank">{{ mySelf.rank }}</span>
              <div class="name">我</div>
            </div>
            <div class="score">{{ mySelf.score }}积分</div>
            <div class="address">{{ mySelf.dealerName || "" }}</div>
          </div>
          <div class="box" v-for="(item, index) in rankingList" :key="index">
            <div style="flex: 1; display: flex;align-items: center;">
              <div :class="'rank rank-' + (index + 1)">{{ index + 1 }}</div>
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="score">{{ item.score }}积分</div>
            <div class="address">{{ item.dealerName || "" }}</div>
          </div>
        </div>
      </div>
    </section>

    <section class="section2">
      <div class="section-box">
        <div class="tab-box">
          <div class="tabs">
            <div
              class="tab"
              :class="activeName == 'newList' ? 'active' : ''"
              @click="handleClick('newList')"
            >
              课程上新
            </div>
            <div
              class="tab"
              :class="activeName == 'hotList' ? 'active' : ''"
              @click="getSelectCourseList(null, 'hotList')"
            >
              本月热榜
            </div>
            <div
              class="tab"
              v-for="(item, index) in dataInfo.typeList"
              :class="courseType == item.id ? 'active' : ''"
              @click="getSelectCourseList(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="more" @click="jumpcompusory">
            <div class="t">更多</div>
            <img src="@/assets/index/y.png" alt="" />
          </div>
        </div>
        <div class="list" v-if="activeName ==='newList'">
          <div
            v-for="(item, index) in dataInfo[activeName]"
            :key="index"
            @click="enterCourse(item)"
            class="box"
          >
            <img :src="item.picUrl" alt="" />
            <div class="item-right-item-text">
              <div class="t">{{ item.name }}</div>
              <div class="d-box">
                <div class="date">
                  {{ parseTime(item.updtime, "{y}-{m}-{d}") }}
                </div>
                <div class="see-box">
                  <img src="../assets/index/see.png" alt="" />
                  <div class="num">{{ item.readNumber }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="list" v-else>
          <div
            v-for="(item, index) in courseList"
            :key="index"
            @click="enterCourse(item)"
            class="box"
          >
            <img :src="item.picUrl" alt="" />
            <div class="item-right-item-text">
              <div class="t">{{ item.name }}</div>
              <div class="d-box">
                <div class="date">
                  {{ parseTime(item.updtime, "{y}-{m}-{d}") }}
                </div>
                <div class="see-box">
                  <img src="../assets/index/see.png" alt="" />
                  <div class="num">{{ item.readNumber }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-box">
        <div class="tab-box">
          <div class="tabs">
            <div class="tab active">专题课程</div>
          </div>
          <div class="more" @click="jumptraining">
            <div class="t">更多</div>
            <img src="@/assets/index/y.png" alt="" />
          </div>
        </div>
        <div class="list">
          <div
            v-for="(item, index) in dataInfo.cltActivityList"
            :key="index"
            @click="enterTraining(item)"
            class="box"
          >
            <img :src="item.pic" alt="" />
            <div class="item-right-item-text">
              <div class="t">{{ item.name }}</div>
              <div class="d-box">
                <div class="date">
                  {{ parseTime(item.addtime, "{y}-{m}-{d}") }}
                </div>
                <div class="see-box">
                  <img src="../assets/index/see.png" alt="" />
                  <div class="num">
                    {{ item.readNumber ? item.readNumber : 0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section3">
      <div class="tab-box">
        <div class="tabs">
          <div class="tab active">社区广场</div>
        </div>
        <div class="more" @click="jumpQuestionList('questionList')">
          <div class="t">更多</div>
          <img src="@/assets/index/y.png" alt="" />
        </div>
      </div>
      <div class="list">
        <div
          class="box"
          v-for="(item, index) in dataInfo.bbsCommunityPostList"
          :key="index"
          @click="questionDetail(item, activeName1)"
        >
          <div class="user-box">
            <div class="photo">
              <img :src="item.addUserPhoto" alt="" />
            </div>
            <div class="user-name-box">
              <div class="user-name">{{ item.addUserName }}</div>
              <div class="date">
                {{ parseTime(item.addtime, "{y}-{m}-{d}") }}
              </div>
            </div>
          </div>
          <div class="content-box">
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.content }}</div>
          </div>

          <div class="reply" v-if="item.commentList.length > 0">
            <div class="user-box">
              <div class="photo">
                <img :src="item.commentList[0].userPhoto || ''" alt="" />
              </div>
              <div class="user-name-box">
                <div class="user-name">{{ item.commentList[0].userName }}</div>
                <div class="date">
                  {{ parseTime(item.commentList[0].addtime, "{y}-{m}-{d}") }}
                </div>
              </div>
            </div>
            <div class="content-box">
              <div class="content">{{ item.commentList[0].content }}</div>
            </div>
          </div>
          <div class="reply reply-empty" v-else>
            <img src="@/assets/index/empty.png" alt="" />
            <div class="t">暂无评论内容</div>
          </div>

          <div class="controller">
            <div>
              <img class="icon" src="@/assets/index/see.png" alt="" />
              <span>{{ item.readNumber || 0 }}</span>
            </div>
            <div>
              <img class="icon" src="@/assets/index/pl.png" alt="" />
              <span>{{ item.commentNumber || 0 }}</span>
            </div>
            <div @click.stop="thumbs(item)">
              <img
                v-if="item.thumbsId"
                :src="require('@/assets/community/area/is-like-icon.png')"
                alt=""
              />
              <img
                v-else
                :src="require('@/assets/community/area/like-icon.png')"
                alt=""
              />
              <span>{{ item.thumbsNumber || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <indexPop
      v-if="showIndexPop"
      :popData="popData"
      @closePop="indexClosePop"
    ></indexPop>
  </div>
</template>

<script>
import {
  getIndex,
  selectCourseList,
  selectUserTaskInfo,
  selectLocusList,
  getCourseNum,
  getHomePersonalInfo,
} from "@/api/user";
import { savePostThumbs } from "@/api/community";
import { getPolyvUrl } from "@/api/live";
import { getToken } from "@/utils/auth"; // 验权
import { parseTime } from "@/utils";
import indexPop from "@/views/myHonor/components/indexPop.vue";
import dateUtil from "@/utils/DateUtil";
import moment from "moment/moment";
import { getRanking, selectNewMedal } from "@/api/honor";
import { notifyAction } from "@/utils";
moment.locale("zh-cn");
export default {
  components: {
    indexPop,
  },
  data() {
    return {
      showWx: false,
      loading: true,
      bannerList: [],
      hotList: [],
      colors: [
        { color: "#FFB800", percentage: 20 },
        { color: "#FFE924", percentage: 50 },
        { color: "#D0760D", percentage: 80 },
      ],
      percentage: 0,
      userBaseInfo: {},
      personInfo: JSON.parse(localStorage.getItem("personInfo")) || {},
      courseType: "",
      activeName: "newList",
      activeName1: "questionList",
      locusList: [],
      courseList: [],
      dataInfo: {
        selectedList: [{ pciUrl: "" }],
        recommendList: [],
        liveList: [],
        hotList: [{ pciUrl: "" }],
        newList: [],
        newShareList: [],
        picList: [],
        questionList: [{}],
        inspireMedalList: [{}],
      },
      // personData: "",
      dialogVisible: false,
      showFixed: true,
      defaultImg: require("@/assets/common/default/item-default.jpg"),
      defaultBanner: require("@/assets/index/banner-default.jpg"),
      defaultCourse: require("@/assets/common/default/item-default.jpg"),
      defaultCommunity: require("@/assets/common/default/item-community-default.jpg"),
      todayWeek: null,
      nowDate: null,
      messageCount: 0,
      rankingList: [],
      mySelf: {},
      showIndexPop: false,
      popData: [],
    };
  },
  filters: {
    filterString: function (value) {
      if (value) {
        return value.slice(0, 10);
      } else {
        return "--";
      }
    },
  },
  created() {
    this.nowDate = dateUtil.getNowDate();
    this.todayWeek = moment().format("dddd");
    var that = this;
    selectLocusList({ pageIndex: 1, pageSize: 5 }).then((res) => {
      if (res.code == 200) {
        that.locusList = res.list;
      }
    });
    // getCourseNum({}).then((res) => {
    //   if (res.code == 200) {
    //     this.personData = res.data;
    //   }
    // });

    getIndex({ type: 1 }).then((res) => {
      if (res.code == 200) {
        var timer = setTimeout(function () {
          that.loading = false;
          clearTimeout(timer);
        }, 300);
        that.dataInfo = res.data;
        that.$forceUpdate();
      }
    });
    // this.selectUserTaskInfo();
    if (this.userInfo.gmStatus == 1) {
      this.dialogVisible = true;
    } else {
      this.dialogVisible = false;
    }
    this.getPersonalInfo();
    this.$store
      .dispatch("GetUnRead", { id: this.userInfo.id })
      .then((res) => {
        if (res.code === 200) {
          this.messageCount = res.data.unreadCount;
        }
      })
      .catch(() => {
        this.loading = false;
      });
    this.getRankingList();
    this.getPopData();
  },
  computed: {
    userInfo: function () {
      console.log(this.$store.getters.userInfo);

      return this.$store.getters.userInfo;
    },
  },
  mounted() {},
  methods: {
    // 点赞
    thumbs(item) {
      savePostThumbs({
        postId: item.id,
        type: item.thumbsId ? 0 : 1,
      }).then((res) => {
        if (res.code === 200) {
          notifyAction(`${item.thumbsId ? "取消" : ""}点赞成功！`, "success");
        }
        item.thumbsId = !item.thumbsId;
        item.thumbsNumber = item.thumbsNumber + (item.thumbsId ? 1 : -1);
      });
    },
    // 查询课程
    getSelectCourseList(id, activeName) {
      this.activeName = activeName ?? '';
      this.courseType = id??null;
      selectCourseList({
        type: id ? 3: 2,
        typeId: id??null,
      }).then((res) => {
        console.log(res);
        this.courseList = res.data;
      });
    },

    // 获取个人信息
    async getPersonalInfo() {
      await this.$store.dispatch("GetPersonInfo", {});
      this.personInfo = JSON.parse(localStorage.getItem("personInfo"))
      // const res = await getHomePersonalInfo();
      // console.log(res.data);
      // this.personInfo = res.data;
    },
    DoOne(key) {
      var v = this.getCookie(key);
      if (!v) {
        if (window.location.href.indexOf("satisfactionSurveyForPcHome") == -1) {
          this.showWx = true;
        }

        //获取第二天凌晨到当前时间的秒数
        var tim_sec =
          24 * 60 * 60 -
          (new Date().getHours() * 60 * 60 +
            new Date().getMinutes() * 60 +
            new Date().getSeconds());
        this.setCookie(key, "1", tim_sec);
      }
    },
    setCookie(name, value, second) {
      if (!second) {
        second = 7 * 24 * 60 * 60; //默认7天
      }
      var exp = new Date();
      exp.setTime(exp.getTime() + second * 1000);
      document.cookie =
        name +
        "=" +
        encodeURI(value) +
        ";expires=" +
        exp.toGMTString() +
        ";path=/";
    },
    getCookie(name) {
      if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(name + "="); //获取字符串的起点
        if (c_start != -1) {
          c_start = c_start + name.length + 1; //获取值的起点
          var c_end = document.cookie.indexOf(";", c_start); //获取结尾处
          if (c_end == -1) c_end = document.cookie.length; //如果是最后一个，结尾就是cookie字符串的结尾
          return decodeURI(document.cookie.substring(c_start, c_end)); //截取字符串返回
        }
      }
      return "";
    },
    parseTime,
    handleClick(val) {
      this.courseType = "";
      this.activeName = val;
    },
    moreHistory() {
      this.$router.push({
        path: "/trajectory",
      });
    },
    jumpcompusory() {
      if (this.courseType) {
        this.$router.push({
          path: `/course?postLabelParentId=${this.courseType}`,
        });
      } else {
        this.$router.push({
          path: "/compulsoryCourse",
        });
      }
    },
    // 跳转到专题
    jumptraining() {
      this.$router.push({
        path: "/training",
      });
    },
    enterDetail(item) {
      if (item.type == 1) {
        this.$router.push({ path: "/courseDetail", query: { id: item.id } });
      } else if (item.type == 2) {
        this.$router.push({
          path: "/specialTest/testPaperDetail",
          query: { id: item.id },
        });
      } else if (item.type == 3) {
        this.jumpQuestion(item);
      } else if (item.type == 4) {
        this.$router.push({
          path: "/scriptDetail",
          query: { id: item.id, type: "list" },
        });
      }
    },
    openURL(item) {
      if (item.urlType == 1) {
        if (item.bannerList && item.bannerList.length) {
          var url =
            "/courseStudy?courseId=" +
            item.bannerList[0].courseId +
            "&coursewareId=" +
            item.bannerList[0].coursewareId +
            "&chapterId=" +
            item.bannerList[0].chapterId +
            "&type=" +
            item.bannerList[0].type;
        }

        this.$router.push({
          path: url,
        });
      } else if (item.urlType == 2) {
        if (item.forwardUrl.indexOf("http") > -1) {
          window.open(item.forwardUrl);
        } else {
          this.$router.push({
            path: item.forwardUrl,
          });
        }
      }
    },
    setDefaultImg(e, url) {
      e.target.src = url;
    },
    selectUserTaskInfo() {
      var that = this;
      selectUserTaskInfo({}).then((res) => {
        if (res.code == 200) {
          that.percentage = parseInt(
            (res.data.growValue /
              (res.data.growValueEnd - res.data.growValueStart)) *
              100
          );
          that.userBaseInfo = res.data;
        } else {
          notifyAction(res.message, "warning");
        }
      });
    },
    enterCourse(item) {
      this.$router.push({
        path: "/courseDetail",
        query: { id: item.id, type: item.typeType },
      });
    },
    enterTraining(item) {
      this.$router.push({
        path: "/trainingDetail",
        query: { id: item.id, name: item.name },
      });
    },

    enterStudyList() {
      this.$router.push({ path: "/compulsoryCourse", query: {} });
    },
    jumpRoad() {
      this.$router.push({ path: "/theRoadToGrowth", query: {} });
    },
    jumpCommunity(item) {
      this.$router.push({ path: "/shareDetail", query: { id: item.id } });
    },
    jumpQuestion(item) {
      this.$router.push({ path: "/cardDetail", query: { id: item.id } });
    },
    questionDetail(item, type) {
      if (type == "questionList") {
        this.jumpQuestion(item);
      } else {
        this.$router.push({
          path: "/scriptDetail",
          query: { id: item.id, type: "list" },
        });
      }
    },
    jumpQuestionList(type) {
      if (type == "questionList") {
        this.$router.push({
          path: "/community",
        });
      } else {
        this.$router.push({
          path: "/scriptPlatform",
        });
      }
    },
    jumpLiveList() {
      this.$router.push({path: "/liveList"});
    },
    jumpLive(item, type) {
      if (this.dataInfo.liveType == "replay" || type == 2) {
        this.$router.push({
          path: "/replayDetail",
          query: { item: JSON.stringify(item) },
        });
      } else {
        var title = item.name;
        var id = item.id;
        this.$router.push({
          path: "/liveDetail",
          query: {
            id: id,
            title: title,
          },
        });
      }
    },
    openTab(url) {
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      a.setAttribute("id", "camnpr");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(document.getElementById("camnpr"));
    },
    checkMoreCourse(type) {
      if (type == "hot") {
        this.$router.push({ path: "/recommend", query: {} });
      } else if (type == "new") {
        this.$router.push({ path: "/course", query: {} });
      }
    },

    getRankingList() {
      getRanking({ count: 10, data: 1, type: 1 })
        .then((res) => {
          if (res.code === 200) {
            this.rankingList = res.data.list;
            this.mySelf = res.data.user;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    getPopData() {
      selectNewMedal({}).then((res) => {
        if (res.code === 200) {
          this.popData = res.data;
          if (this.popData.length) {
            this.showIndexPop = true;
          }
        }
      });
    },

    indexClosePop() {
      this.popData = [];
      this.showIndexPop = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.all {
  // max-width: 1920px;
  padding-bottom: 38px;
  margin: 0 auto;
  background: #f6f6f6;
  text-align: left;
  .seciton1 {
    display: flex;
    padding-right: 82px;
    align-items: stretch;
    .left-box {
      width: 79%;
      .user-card-wraper {
        .user-info {
          display: flex;
          align-items: center;
          padding-top: 22px;
          position: relative;
          justify-content: space-between;
          .avatar-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            overflow: hidden;
            height: 235px;
            img {
              height: 100%;
              object-fit: cover;
            }
          }
          .avatar-box {
            padding-left: 82px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            z-index: 2;
            width: calc(40% - 82px);
            .avatar {
              display: flex;
              align-items: baseline;
              position: relative;

              .a-photo {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                border: 1px solid rgba($color: #000000, $alpha: 0.2);
              }
              .a-icon {
                width: 43px;
                height: 43px;
                margin-left: 6px;
              }
            }
            .user-name {
              color: #000000;
              margin-top: 10px;
              font-size: 21px;
              font-weight: 600;
              .level {
                background-color: #000;
                border-radius: 0px 10px 10px 10px;
                padding: 2px 12px;
                color: #ffcb78;
                font-size: 13px;
              }
            }
            .date {
              font-size: 12px;
              margin-top: 5px;
            }
          }
          .user-info-box {
            background: #fefdfe;
            position: relative;
            z-index: 2;
            border-radius: 19px;
            display: flex;
            align-items: center;
            padding: 29px 20px 20px;
            justify-content: space-around;
            flex: 1;
            text-align: center;
            .info-box {
              //padding-right: 40px;
              //padding-left: 40px;
              position: relative;
              flex: 1;
              &::after {
                position: absolute;
                content: "";
                height: 50%;
                width: 1px;
                background-color: rgba($color: #000000, $alpha: 0.1);
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
              .t {
                font-weight: 400;
                font-size: 12px;
                color: #000000;
                font-weight: 600;
              }
              .d {
                font-size: 24px;
                color: #000000;
                padding: 30px 0;
                font-weight: 600;
                display: block;
              }
              .con {
                display: flex;
                font-size: 12px;
                color: #000000;
                align-items: center;
                height: 21px;
                font-weight: 600;
                img {
                  width: 7px;
                  padding: 0 1px;
                }
                span {
                  font-size: 13px;
                  color: #000000;
                  font-family: AudiTypeV01-Bold;
                  display: block;
                  padding-top: 3px;
                }
              }
              .info-btn {
                cursor: pointer;
                width: 70px;
                height: 21px;
                background: #000000;
                border-radius: 3px 3px 3px 3px;
                font-size: 12px;
                color: #f6f6f6;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &:nth-of-type(1) {
                margin-left: 0;
              }
              &:nth-last-of-type(1) {
                margin-right: 0;
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
      .section-box {
        display: flex;
        margin-top: 20px;
        padding-left: 64px;
        justify-content: space-between;
        .box {
          background: #ffffff;
          border-radius: 19px 19px 19px 19px;
          padding: 20px 40px;
          width: 40%;
          cursor: pointer;
          margin-right: 20px;
          z-index: 99;
          .box-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .t {
              font-weight: 600;
              font-size: 22px;
              color: #000000;
            }
            img {
              width: 11px;
            }
          }
          .content-box {
            margin-top: 30px;
            position: relative;
            .bg {
              width: 100%;
              height: 220px;
              img {
                width: 100%;
                border-radius: 12px 12px 0 0;
                display: block;
                height: 220px;
                object-fit: cover;
              }
            }
            .reply-box {
              position: absolute;
              left: -5px;
              top: 20px;
              width: 78px;
              img {
                width: 100%;
              }
              .reply-t {
                display: flex;
                align-items: center;
                position: absolute;
                height: 20px;
                top: 0;
                justify-content: center;
                width: 73px;
                .live {
                  width: 10px;
                }
                .t {
                  font-weight: 400;
                  font-size: 9px;
                  color: #ffffff;
                  margin-left: 8px;
                }
              }
            }
            .content {
              background: #f5f5f5;
              border-radius: 0px 0px 16px 16px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 20px 20px 28px;
              .t {
                font-weight: 600;
                font-size: 14px;
                color: #000000;
              }
              .d {
                font-weight: 600;
                font-size: 14px;
                color: #000000;
                margin-top: 10px;
              }
              .d-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                width: 100%;
                .date {
                  font-weight: 600;
                  font-size: 12px;
                  color: #858485;
                }
                .see-box {
                  display: flex;
                  align-items: center;
                  img {
                    width: 16px;
                  }
                  .num {
                    font-weight: 600;
                    font-size: 12px;
                    color: #b1b1b1;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          &:nth-last-of-type(1) {
            width: 20%;
            margin-right: 0;
            .content {
              margin-top: 30px;
              .h-item {
                text-align: left;
                position: relative;
                cursor: pointer;
                padding-left: 10px;
                border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
                padding-bottom: 25px;
                .t {
                  font-weight: 600;
                  font-size: 14px;
                  color: #282728;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .d {
                  font-weight: 600;
                  font-size: 12px;
                  margin-top: 5px;
                  color: #a9a8a9;
                }
                &::after {
                  content: "";
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  position: absolute;
                  top: 0;
                  border: 1px solid #000;
                  left: -4.3px;
                  background-color: #fff;
                }
                &:nth-of-type(1) {
                  &::after {
                    background-color: #e13240;
                    border: 1px solid #e13240;
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .right-box {
      width: 21%;
      margin-top: 22px;
      align-items: stretch;
      margin-left: 23px;
      display: flex;
      flex-direction: column;
      .head-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 46px;
        padding: 0 20px;
        background: linear-gradient(
          180deg,
          #fdf7f8 0%,
          rgba(253, 247, 248, 0) 100%
        );
        img {
          width: 79px;
        }
        .t {
          font-weight: 400;
          font-size: 16px;
          color: #8d1c1c;
        }
      }
      .content {
        background: #ffffff;
        flex: 1;
        border-radius: 20px;
        flex: 1;
        padding: 28px 20px;
        .box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
          padding-bottom: 10px;
          padding-top: 15px;
          &:nth-of-type(1) {
            border-color: #000;
          }
          .rank {
            font-weight: 500;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #adadad;
            min-width: 18px;
            height: 18px;
            border-radius: 50%;
          }
          .rank-1 {
            background: linear-gradient(
              180deg,
              #fbd268 0%,
              rgba(253, 228, 138, 0) 100%
            );
            font-weight: 500;
            font-size: 14px;
            color: #f1b03c;
          }
          .rank-2 {
            background: linear-gradient(
              180deg,
              #ff822c 0%,
              rgba(255, 130, 44, 0) 100%
            );
            color: #da8a53;
          }
          .rank-3 {
            background: linear-gradient(
              180deg,
              #d8b789 0%,
              rgba(216, 183, 137, 0) 100%
            );
            color: #c28f58;
          }
          .name {
            flex: 1;
            font-weight: 600;
            font-size: 13px;

            text-align: left;
            color: #000000;
            padding-left: 12px;
          }
          .score {
            font-weight: 600;
            font-size: 13px;
            text-align: left;
            color: #757575;
            width: 30%;
          }
          .address {
            font-weight: 600;
            font-size: 13px;
            color: #adadad;
            width: 30%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .box:first-child {
          .name {
            width: auto;
            flex: 1;
          }
          .score {
            //width: 86%;
          }
        }
      }
    }
  }

  .section2 {
    margin: 20px 82px 0px 64px;
    background-color: #fff;
    border-radius: 19px;
    padding: 34px 40px;
    .section-box {
      margin-top: 55px;
      .tab-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tabs {
          display: flex;
          align-items: center;
          .tab {
            font-weight: 400;
            cursor: pointer;
            font-size: 22px;
            color: #a9a9a9;
            margin-right: 31px;
            position: relative;
            &.active {
              color: #282728;
              font-weight: 600;
            }
            &::after {
              height: 20px;
              width: 1px;
              content: "";
              position: absolute;
              right: -18px;
              top: 50%;
              background: #a9a9a9;
              transform: translateY(-50%);
            }
            &:nth-last-of-type(1) {
              &::after {
                display: none;
              }
            }
          }
        }
        .more {
          font-weight: 400;
          font-size: 14px;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #7d7d7d;
          img {
            width: 6px;
            display: block;
            margin-left: 13px;
          }
        }
      }
      .list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 44px;
        .box {
          margin-right: 20px;
          margin-bottom: 27px;
          cursor: pointer;
          width: 18%;
          img {
            width: 100%;
            height: 160px;
            object-fit: cover;
            border-radius: 13px;
          }
          &:nth-of-type(5n) {
            margin-right: 0;
          }
          .item-right-item-text {
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .t {
              font-weight: 600;
              font-size: 14px;
              text-align: left;
              color: #000000;
              height: 33px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .d-box {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 10px;
              width: 100%;
              .date {
                font-size: 12px;
                color: #000;
                font-weight: 600;
              }
              .see-box {
                display: flex;
                align-items: center;
                img {
                  width: 16px;
                  height: auto;
                }
                .num {
                  font-weight: 600;
                  font-size: 12px;
                  color: #b1b1b1;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
      &:nth-of-type(1) {
        margin-top: 0;
      }
    }
  }

  .section3 {
    margin: 20px 82px 0 64px;
    background-color: #fff;
    border-radius: 19px;
    padding: 34px 40px;
    .tab-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tabs {
        display: flex;
        align-items: center;
        .tab {
          font-weight: 600;
          cursor: pointer;
          font-size: 22px;
          color: #a9a9a9;
          margin-right: 31px;
          position: relative;
          &.active {
            color: #282728;
          }
          &::after {
            height: 20px;
            width: 1px;
            content: "";
            position: absolute;
            right: -18px;
            top: 50%;
            background: #a9a9a9;
            transform: translateY(-50%);
          }
          &:nth-last-of-type(1) {
            &::after {
              display: none;
            }
          }
        }
      }
      .more {
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #7d7d7d;
        img {
          width: 6px;
          display: block;
          margin-left: 13px;
        }
      }
    }
    .list {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 40px;
      .box {
        background: #f6f6f6;
        border-radius: 20px 20px 20px 20px;
        flex: 1;
        margin-right: 15px;
        padding: 26px 20px 20px;
        cursor: pointer;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
        .user-box {
          display: flex;
          align-items: center;
          .photo {
            width: 40px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .user-name-box {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .user-name {
              font-weight: 600;
              font-size: 15px;
              color: #000000;
            }
            .date {
              font-weight: 600;
              font-size: 12px;
              color: #5a6160;
              margin-top: 3px;
            }
          }
        }
        .content-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 23px;
          padding-bottom: 10px;
          .title {
            font-weight: 600;
            font-size: 15px;
            color: #000000;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 33px;
          }
          .content {
            text-align: left;
            font-weight: 600;
            font-size: 12px;
            color: #5c5c5c;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 10px;
            height: 60px;
            line-height: 1.6;
          }
        }
        .controller {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 14px;
          img {
            width: 15px;
          }
          div {
            display: flex;
            align-items: center;
            span {
              font-weight: 400;
              font-size: 12px;
              color: #b1b1b1;
              margin-left: 11px;
              display: block;
            }
          }
        }
        .reply {
          background-color: #fff;
          border-radius: 15px;
          margin-top: 14px;
          padding: 14px 17px;
          height: 135px;
          .content-box {
            border: 0;
            padding: 0;
          }
          &.reply-empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              width: 120px;
            }
            .t {
              font-size: 12px;
              color: #b1b1b1;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .all {
    .seciton1 {
      .left-box {
        .user-card-wraper {
          .user-info {
            .avatar-box {
              .avatar {
                .a-icon {
                  width: 36px;
                  height: 36px;
                }
              }
              .user-name {
                font-size: 18px;
              }
              .date {
                font-size: 10px;
                margin-top: 5px;
              }
            }
            .user-info-box {
              padding: 29px 10px 20px 10px;
              .info-box {
                //margin-right: 30px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1550px) {
  .all {
    .seciton1 {
      .left-box {
        .user-card-wraper {
          .user-info {
            .user-info-box {
              padding: 29px 10px 20px 10px;
              .info-box {
                margin-right: 0px;
                //padding: 0 30px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .all {
    .seciton1 {
      padding-right: 58px;
      .left-box {
        width: 76%;
        .user-card-wraper {
          .user-info {
            .avatar-box {
              padding: 0 38px 0 46px;
              .a-photo {
                width: 50px;
                height: 50px;
              }
              .a-icon {
                width: 30px;
                height: 30px;
              }
              .user-name {
                font-size: 15px;
              }
            }
            .user-info-box {
              padding: 20px 20px 15px;
              .info-box {
                margin-right: 0;
              }
            }
          }
        }
        .section-box {
          padding-left: 46px;
          .box {
            margin-right: 16px;
            padding: 15px 28px;
            .box-head {
              .t {
                font-size: 15px;
              }
              img {
                width: 8px;
              }
            }
            .content-box {
              .bg {
                height: 157px;
                img {
                  height: 157px;
                }
              }
              .content {
                padding: 14px 14px 20px;
                .t {
                  font-size: 12px;
                }
                .d {
                  font-size: 12px;
                }
              }
            }
            &:nth-last-of-type(1) {
              .content {
                .h-item {
                  .t {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
      .right-box {
        width: 24%;
        margin-left: 16px;
      }
    }
    .section2 {
      margin: 14px 58px 0 46px;
      padding: 24px 30px;
      .section-box {
        .tab-box {
          .tabs {
            .tab {
              font-size: 18px;
            }
          }
        }
      }
    }
    .section3 {
      margin: 14px 58px 27px 46px;
      padding: 24px 30px;
      .tab-box {
        .tabs {
          .tab {
            font-size: 18px;
          }
        }
      }
      .list {
        margin-top: 28px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wh-tips {
  padding: 20px;
  text-align: left;
  font-size: 16px;

  & p {
    line-height: 28px;
  }
  & a {
    color: blue;
    cursor: pointer;
  }
  .title {
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    & i {
      cursor: pointer;
    }
  }
}
::v-deep .el-dialog {
  margin-top: calc(50vh - 200px) !important;
}
::v-deep {
  .el-tabs__nav-wrap::after {
    background-color: transparent;
  }
  .el-tabs__item {
    font-size: 20px;
    color: #b3b3b3;
  }
  .el-tabs__active-bar {
    background-color: #f50537;
  }
  .el-carousel__item {
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}
.tool-tips {
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 5px 0;
}
.locus-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-wraper {
  width: 1200px;
  margin: 20px auto;
}
.dialog-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}
::v-deep .custom-tooltip {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.history-wraper {
  width: 237px;
  padding: 20px 20px;
  height: 260px;
  background: linear-gradient(270deg, #d9d9d9 0%, #f5f5f5 11.68%);
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .banner .el-skeleton {
  width: 98%;
}
.dialog-footer .el-button {
  width: 100%;
  background-color: #ffffff;
  color: #f50537;
  font-size: 20px;
  padding: 15px 0;
  border: none;
  border-top: 1px solid #f2f2f2;
}
.title-gmstatus-wraper {
  width: 100%;
  height: 240px;
  position: relative;
}
.from-parment {
  font-size: 14px;
  color: #666666;
  text-align: left;

  height: 14px;
}
.title-gmstatus-wraper p {
  font-size: 32px;
  line-height: 24px;
  padding-top: 90px;
  /* identical to box height, or 75% */

  font-weight: 600;
  color: #000000;
}
.title-gmstatus {
  position: absolute;
  top: -45px;
  left: 50%;
  margin-left: -100px;
}
.user-task-info {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  font-size: 12px;
  font-weight: 500;
  & p {
    background-color: #ffffff;
    width: 115px;
    height: 36px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 36px;
  }
  & p:first-child {
    margin-right: 15px;
  }
}
.course-list {
  width: 100px;
  height: 100px;
  position: fixed;
  right: 50px;
  bottom: 20%;

  cursor: pointer;
}
.course-list .close {
  position: relative;
  margin-left: 33px;
  /* margin-top: 15px; */
  top: 9px;
}
.user-card-right-small {
  display: flex;
  & img {
    width: 20px;
    height: auto;
  }
}
.flex-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-content {
  margin-top: 15px;
}

.item-left {
  width: 425px;
  height: 268px;
  border-radius: 5px 5px 0 0;
  background: rgba(245, 245, 245, 1);
  & img {
    width: 100%;
    object-fit: cover;
    height: 238px;
    border-radius: 5px 5px 0 0;
  }
  .item-left-item-text {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 12px;
    & span:first-child {
      display: inline-block;
      width: 320px;
      font-size: 14px;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    & span:last-child {
      display: inline-block;
      position: relative;
      top: 2px;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
    }
  }
}
.item-right {
  .item-right-item {
    width: 180px;
    height: 126px;
    display: flex;
    flex-direction: column;
    background: rgba(245, 245, 245, 1);
    margin-bottom: 15px;
    border-radius: 5px 5px 0 0;
    & img {
      width: 100%;
      height: 90px;
      border-radius: 5px;
      object-fit: cover;
    }
    .item-right-item-text {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 12px;
      & span:first-child {
        display: inline-block;
        width: 85px;
        font-size: 14px;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
      & span:last-child {
        display: inline-block;
        position: relative;
        top: 2px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
      }
    }
  }
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  width: 750px;
}

.author img {
  margin-right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  object-fit: cover;
}
.line {
  width: 90%;
  height: 2px;
  border-bottom: 2px solid #b3b3b3;
  margin: 15px auto;
}
.user-task-info-bottom {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  padding-top: 0px;
  & div {
    & p:first-child {
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      margin-top: 0;
    }
    & p:last-child {
      color: #000000;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.q-item {
  width: 245px;
  height: 288px;
  padding: 20px;
  background: rgba(248, 248, 248, 1);
  border-radius: 5px 5px 0 0;
  text-align: left;
  cursor: pointer;
  .q-item-user {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    padding-top: 10px;
    & img {
      width: 30px;
      height: 30px;
      border-radius: 2px;
    }
  }
  .q-item-title {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    height: 37px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "audi-zh-regular";
  }
  .q-b-wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-top: 5px;
    color: rgba(179, 179, 179, 1);
    & img {
      width: 16px;
      position: relative;
      top: 3px;
    }
  }
  .q-item-content {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    height: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
    padding: 10px 0;
    padding-bottom: 20px;

    margin-bottom: 10px;
  }
  .flex-user {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: 12px;
    color: #b3b3b3;
    & span {
      font-size: 14px;
      color: #000000;
    }
  }
}
.user-card-header {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}

.user-post-name {
  font-size: 12px;
  line-height: 14px;

  /* 学习系统-主色-黑 */

  color: #000000;
}
.history-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & span {
    font-size: 16px;
    font-weight: 500;
  }
  & span:last-child {
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    cursor: pointer;
  }
}
.banner {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.banner .carousel {
  width: 640px;
}
.user-card {
  width: 260px;
  height: 300px;
  background: linear-gradient(270deg, #f5f5f5 88.32%, #e0e0e0 100%),
    linear-gradient(136.08deg, rgba(0, 0, 0, 0) 88.21%, rgba(0, 0, 0, 0.1) 100%);
  position: relative;
  .t-bg {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .user-card-left {
    width: 50%;
    & img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .user-card-right {
    width: 50%;

    .jp {
      width: 70px;
      height: auto;
    }
  }
}

::v-deep .skeleton .item-wraper {
  display: flex;
  cursor: pointer;
}
h3 {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  text-align: left;
}
.list-item {
  width: 1200px;
  margin: 0 auto;
  .more {
  }
}
.list-item-card {
  width: 283px;
  /* height: 259px; */
  background-color: #f5f5f5;
  cursor: pointer;
  margin-right: 24px;
  position: relative;
  border-radius: 5px 5px 0 0;
  // padding-bottom: 15px;
  & img {
    border-radius: 5px 5px 0 0;
  }
  .live-tips {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    background: rgba(245, 5, 55, 1);
    padding: 5px 15px;
    color: #ffffff;
    border-radius: 0px 0px 20px 0px;
  }
}
.list-item-card:last-child {
  margin-right: 0;
}
.list-item-card img {
  width: 120px;
  height: 90px;
  object-fit: cover;
}
.list-item-card .title {
  font-size: 14px;
  color: #000000;
  overflow: hidden;
  width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding: 0;
  margin: 5px 0;
  line-height: 22px;
  height: 40px;
}
.item-info {
  text-align: left;
  margin-top: 10px;
  padding-left: 5px;
}
.item-info span {
  font-size: 14px;
  color: #999999;
  padding: 10px;
}
</style>
