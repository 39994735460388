import request from "@/utils/request";
// 登录
export function login(params) {
  return request({
    url: "/aitp-master/portal/login",
    mtf: "st",
    data: params,
  });
}
//查询用户信息
export function getInfo(params) {
  return request({
    url: "/aitp-master/portal/selectUserInfo",
    mtf: "et",
    params: params,
  });
}
// 查询菜单
export function getMenuList(params) {
  return request({
    url: "/aitp-master/portal/selectMenuList",
    mtf: "et",
    params: params,
  });
}

// 获取验证码
export function verifyCode(params) {
  return request({
    url: "/aitp-master/common/verifyCode",
    mtf: "et",
    params: params,
  });
}
// 获取个人签到
export function selectSignRecord(params) {
  return request({
    url: "/aitp-master/portal/selectSignRecord",
    mtf: "st",
    params: params,
  });
}
// 签到
export function signIn(params) {
  return request({
    url: "/aitp-master/portal/signIn",
    mtf: "st",
    data: params,
  });
}

// 获取用户任务信息
export function selectUserTaskInfo(params) {
  return request({
    url: "/aitp-master/portal/selectUserTaskInfo",
    mtf: "et",
    params: params,
  });
}
// 获取用户等级信息
export function selectUserLevelInfo(params) {
  return request({
    url: "/aitp-master/portal/selectUserLevelInfo",
    mtf: "et",
    params: params,
  });
}

// 获取用户成长值流水
export function selectGrowValueFlow(params) {
  return request({
    url: "/aitp-master/portal/selectGrowValueFlow",
    mtf: "et",
    params: params,
  });
}
// 获取用户学分流水
export function selectCreditFlow(params) {
  return request({
    url: "/aitp-master/portal/selectCreditFlow",
    mtf: "et",
    params: params,
  });
}
// 获取用户积分流水
export function selectIntegralFlow(params) {
  return request({
    url: "/aitp-master/portal/selectIntegralFlow",
    mtf: "et",
    params: params,
  });
}
// 获取用户站内信
export function getUserMail(params) {
  return request({
    url: "/aitp-master/portal/userMail/list",
    mtf: "et",
    params: params,
  });
}
// 站内信已读
export function readMessage(params) {
  return request({
    url: "/aitp-master/portal/userMail/read",
    mtf: "st",
    params: params,
  });
}
// 站内信未读数量
export function unreadCount(params) {
  return request({
    url: "/aitp-master/portal/userMail/unreadCount",
    mtf: "et",
    params: params,
  });
}

// 登出
export function logout(params) {
  return request({
    url: "/aitp-master/portal/logout",
    mtf: "st",
    data: params,
  });
}
// 我的权益
export function selectMyRights(params) {
  return request({
    url: "/aitp-master/portal/selectMyRights",
    mtf: "et",
    params: params,
  });
}
// 我的徽章
export function selectMymedal(params) {
  return request({
    url: "/aitp-master/portal/selectMymedal",
    mtf: "et",
    params: params,
  });
}
// 换取后台登录token
export function portalToAdmin(params) {
  return request({
    url: "/aitp-master/portal/portalToAdmin",
    mtf: "st",
    data: params,
  });
}
// 获取首页数据
export function getIndex(params) {
  return request({
    url: "/aitp-master/portal/home/index",
    mtf: "et",
    params: params,
  });
}

// 查询课程列表数据
export function selectCourseList(params) {
  return request({
    url: "/aitp-master/portal/home/selectCourseList",
    mtf: "et",
    params: params,
  });
}
export function refreshToken(data) {
  return request({
    url: "/aitp-master/common/refreshToken",
    mtf: "st",
    data: data,
  });
}
// 修改头像
export function updatePhoto(params) {
  return request({
    url: "/aitp-master/portal/updatePhoto",
    mtf: "st",
    data: params,
  });
}
// 修改密码
export function updatePassword(params) {
  return request({
    url: "/aitp-master/portal/updatePassword",
    mtf: "st",
    data: params,
  });
}
// 查询述职报告
export function selectManagerDownloadList(params) {
  return request({
    url: "/aitp-master/portal/managerDownload/selectManagerDownloadList",
    mtf: "et",
    params: params,
  });
}
// 获取截止上传时间
export function selectUploadStopTime(params) {
  return request({
    url: "/aitp-master/portal/managerDownload/selectUploadStopTime",
    mtf: "st",
    data: params,
  });
}
// 上传述职报告
export function uploadReport(params) {
  return request({
    url: "/aitp-master/portal/managerDownload/uploadReport",
    mtf: "st",
    data: params,
  });
}
// 批量下载
export function downloadall(params) {
  return request({
    url: "/aitp-master/portal/managerDownload/downloadall",
    mtf: "et",
    params: params,
  });
}
// 获取经销商工作报告上传情况
export function selectUploadNumber(params) {
  return request({
    url: "/aitp-master/portal/managerDownload/selectUploadNumber",
    mtf: "et",
    params: params,
  });
}
// 获取旧系统信息
export function getOldAitpSystemUrl(params) {
  return request({
    url: "/aitp-master/portal/getOldAitpSystemUrl",
    mtf: "et",
    params: params,
  });
}
// 查询学习轨迹
export function selectLocusList(params) {
  return request({
    url: "/aitp-master/portal/home/selectLocusList",
    mtf: "et",
    params: params,
  });
}
// 查询个人数据
export function getCourseNum(params) {
  return request({
    url: "/aitp-master/portal/home/getCourseNum",
    mtf: "et",
    params: params,
  });
}

// 查询首页个人数据
export function getHomePersonalInfo(params) {
  return request({
    url: "/aitp-master/portal/home/selectPersonalInfo",
    mtf: "et",
    params: params,
  });
}

// 模糊搜索
export function searchList(params) {
  return request({
    url: "/aitp-master/portal/home/searchList",
    mtf: "et",
    params: params,
  });
}

export function generatePresignedUrl(params) {
  return request({
    url: "/aitp-master/common/generatePresignedUrl",
    mtf: "et",
    params: params,
  });
}
