
export default {
  routes: [
      {
          path: "/theRoadToGrowth",
          name: "theRoadToGrowth",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'成长之路'},
          component: () =>
            import(/* webpackChunkName: "theRoadToGrowth" */ "@/views/theRoadToGrowth"),
        },
        {
          path: "/theRoad",
          name: "theRoad",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'成长之路'},
          component: () =>
            import(/* webpackChunkName: "theRoadToGrowth" */ "@/views/theRoadToGrowth/road"),
        },
        {
          path: "/growthRoom",
          name: "growthRoom",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'成长空间'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/persionCenter/growthRoom/index.vue"),
        },
        {
          path: "/taskDetail",
          name: "taskDetail",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'成长空间'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/persionCenter/growthRoom/taskDetail.vue"),
        },
        {
          path: "/applyManagement",
          name: "applyManagement",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'报名管理'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/persionCenter/growthRoom/applyManagement.vue"),
        },
        {
          path: "/receiptManagement",
          name: "receiptManagement",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'回执管理'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/persionCenter/growthRoom/receiptManagement.vue"),
        },
        {
          path: "/taskAdd",
          name: "taskAdd",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'在线任务创建'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/persionCenter/growthRoom/taskAdd.vue"),
        },
        {
          path: "/taskStat",
          name: "taskStat",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'在线任务统计'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/persionCenter/growthRoom/taskStat.vue"),
        },
        {
          path: "/onlineTaskDetail",
          name: "onlineTaskDetail",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'在线任务详情'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/persionCenter/growthRoom/onlineTaskDetail.vue"),
        },
        {
          path: "/onlineTaskLearn",
          name: "onlineTaskLearn",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'在线任务学习'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/persionCenter/growthRoom/onlineTaskLearn.vue"),
        },
        {
          path: "/classRecord",
          name: "classRecord",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'上课记录'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/persionCenter/growthRoom/classRecord.vue"),
        },


  ],
  // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
  // path 的值与 iframeRoutePath 相等
  iframes: []
};
