<script>
import {selectNewMedal} from "@/api/honor";

export default {
  name: 'indexPop',
  props: ['popData'],
  data() {
    return {
      currentData: [],
      currentIndex: 0,
    }
  },
  created() {
    this.currentData = this.popData
  },
  methods: {

    closePop() {
      if (this.currentIndex === this.currentData.length - 1) {
        this.$emit('closePop', false)
      }
      this.currentIndex++

    },


  },
}
</script>

<template>
  <div class="pop-content">
    <div class="content-box">

      <div class="honor">
        <div class="h-title">{{currentData[currentIndex].name}}</div>
        <img :src="currentData[currentIndex].lightPicUrl" alt="">
        <div class="h-sub">恭喜您获得{{currentData[currentIndex].name}}</div>
        <div class="btn">
          <div class="download" @click="closePop()">确定</div>
        </div>
      </div>

    </div>
  </div>

</template>

<style scoped lang="scss">
.pop-content {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 999;
  .content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-size: 15px;
      div {
        width: 165px;
        line-height: 36px;
        border-radius: 7px;
      }
      .download {
        background-color: #8D1C1C;
        border: 1px solid #8D1C1C;
        margin-right: 12px;
      }
      .cancel {
        background-color: rgba(0,0,0,0);
        color: #fff;
        border: 1px solid #FFFFFF;
      }
    }
    .honor {
      width: 240px;
      text-align: center;
      .h-title {
        font-family: "DFPKingGothicGB-Semibold";
        font-size: 18px;
        color: #FFFFFF;
        margin-bottom: 20px;
      }
      img {
        width: 150px;
        object-fit: contain;
      }
      .h-sub {
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
        margin-top: 20px;
        line-height: 20px;
      }
      .btn {
        margin-top: 20px;
        .download {
          margin-right: 0;
        }
        .cancel {
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
