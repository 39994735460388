
import request from '@/utils/request'
// 分享列表
export function selectCommunityShareList(params) {
    return request({
        url: '/aitp-master/portal/communityInteractive/selectCommunityShareList',
         mtf: 'et',
        params: params
    })
}


// 验证话题有效性
export function getTopicEffectiveness(params) {
    return request({
        url: '/aitp-master/portal/communityInteractive/getTopicEffectiveness',
        mtf: 'st',
        params: params
    })
}
// 创建分享
export function saveCommunityShare(params) {
    return request({
        url: '/aitp-master/portal/communityInteractive/saveCommunityShare',
        mtf: 'st',
        data: params
    })
}
// 查询话题列表
export function selectRelatedTopicsList(params) {
    return request({
        url: '/aitp-master/portal/communityInteractive/selectRelatedTopicsList',
         mtf: 'et',
        params: params
    })
}
// 上传图片
export function getCredential(params) {
    return request({
        url: '/aitp-master/common/getCredential',
         mtf: 'et',
        params: params
    })
}
// 提问列表
export function selectCommunityQuestionList(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/selectCommunityQuestionList',
         mtf: 'et',
        params: params
    })
}
// 分享详情
export function selectCommunityShareDetail(params) {
    return request({
        url: '/aitp-master/portal/communityInteractive/selectCommunityShareDetail',
         mtf: 'et',
        params: params
    })
}
// 分享点赞
export function shareThumbsUp(params) {
    return request({
        url: '/aitp-master/portal/communityInteractive/shareThumbsUp',
        mtf: 'st',
        params: params
    })
}
// 新建分享评论
export function saveCommunityShareComment(params) {
    return request({
        url: '/aitp-master/portal/communityInteractive/saveCommunityShareComment',
        mtf: 'st',
        data: params
    })
}

// 分享评论列表
export function selectCommunityShareCommentList(params) {
    return request({
        url: '/aitp-master/portal/communityInteractive/selectCommunityShareCommentList',
         mtf: 'et',
        params: params
    })
}
// 分享评论点赞 取消点赞
export function shareThumbsUpComment(params) {
    return request({
        url: '/aitp-master/portal/communityInteractive/shareThumbsUpComment',
        mtf: 'st',
        params: params
    })
}
// 新建提问
export function saveCommunityQuestion(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/saveCommunityQuestion',
        mtf: 'st',
        data: params
    })
}
// 提问详情
export function selectCommunityQuestionDetail(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/selectCommunityQuestionDetail',
         mtf: 'et',
        params: params
    })
}
// 提问详情回答列表
export function selectCommunityQuestionCommentList(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/selectCommunityQuestionCommentList',
         mtf: 'et',
        params: params
    })
}
// 我也想问  点赞
export function questionThumbsUp(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/questionThumbsUp',
        mtf: 'st',
        params: params
    })
}
// 新建提问回答
export function saveCommunityQuestionComment(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/saveCommunityQuestionComment',
        mtf: 'st',
        data: params
    })
}
// 删除回答
export function delCommunityQuestion(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/delCommunityQuestion',
        mtf: 'st',
        data: params
    })
}
// 用户列表
export function selectUserList(params) {
    return request({
        url: '/aitp-master/common/selectUserList',
         mtf: 'et',
        params: params
    })
}
// 邀请回答
export function invitationAnswer(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/invitationAnswer',
         mtf: 'et',
        params: params
    })
}
// 删除回答及评论
export function delCommunityQuestionComment(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/delCommunityQuestionComment',
        mtf: 'st',
        params: params
    })
}
// 提问点赞
export function questionThumbsUpComment(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/questionThumbsUpComment',
        mtf: 'st',
        params: params
    })
}

// 敏感词校验
export function checkBaiduWords(params) {
    return request({
        url: 'https://aip.baidubce.com/rest/2.0/solution/v1/text_censor/v2/user_defined',
         mtf: 'et',
        params: params
    })
}
// 提问话题列表
export function selectQuestionRelatedTopicsList(params) {
    return request({
        url: '/aitp-master/portal/CommunityInteractiveQuestion/selectRelatedTopicsList',
         mtf: 'et',
        params: params
    })
}
// 敏感词校验
export function selectSensitiveWords(params) {
    return request({
        url: '/aitp-master/common/selectSensitiveWords',
        mtf: 'st',
        data: params
    })
}

// 查询社群共创列表
export function selectCommunityPostList(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/selectCommunityPostList',
        mtf: 'et',
        params: params
    })
}

// 保存公告接口（新建-编辑)
export function saveAndUpdate(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/saveAndUpdate',
        mtf: 'st',
        data: params
    })
}

// 标签列表(下拉框)
export function listAllLabel(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/listAllLabel',
        mtf: 'et',
        params: params
    })
}

// 查询账号信息列表
export function selectUserByName(params) {
    return request({
        url: '/aitp-master/common/selectUserByName',
        mtf: 'et',
        params: params
    })
}

// 关注-取消关注
export function saveAttention(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/saveAttention',
        mtf: 'et',
        params: params
    })
}

// 收藏-取消收藏
export function savePostCollect(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/savePostCollect',
        mtf: 'et',
        params: params
    })
}

// 点赞-取消点赞
export function savePostThumbs(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/savePostThumbs',
        mtf: 'et',
        params: params
    })
}
// 查询热榜列表
export function selectHotList(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/selectHotList',
        mtf: 'et',
        params: params
    })
}

// 查询详情
export function getById(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/getById',
        mtf: 'et',
        params: params
    })
}

// 保存评论接口
export function addComment(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/addComment',
        mtf: 'st',
        data: params
    })
}

// 评论列表接口
export function listComment(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/listComment',
        mtf: 'et',
        params: params
    })
}

// 查询个人主页-各种数量
export function selectPersonalCount(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/selectPersonalCount',
        mtf: 'et',
        params: params
    })
}

// 删除帖子
export function delPostInfo(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/delPostInfo',
        mtf: 'et',
        params: params
    })
}

// 删除帖子
export function getListByComment(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/getListByComment',
        mtf: 'et',
        params: params
    })
}

// 查询我的关注列表
export function selectFollowList(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/selectFollowList',
        mtf: 'et',
        params: params
    })
}

// 查询我的关注列表
export function deleteComment(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/deleteComment',
        mtf: 'st',
        params: params
    })
}

// 查询关注我的列表
export function selectFollowMyList(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/selectFollowMyList',
        mtf: 'et',
        params: params
    })
}

// 评论消息数量
export function getReadCount(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/getReadCount',
        mtf: 'et',
        params: params
    })
}

// 一键已读评论
export function readComment(params) {
    return request({
        url: '/aitp-master/portal/bbsCommunity/readComment',
        mtf: 'et',
        params: params
    })
}













