import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'// Progress 进度条样式
import { Message } from 'element-ui'
import { getToken,setToken } from '@/utils/auth' // 验权
import config from '@/config';
import { pvTrack } from './utils/track'
const whiteList = ['/login','/oauth','/satisfactionSurvey','/satisfactionSurveyHome','/satisfactionSurveyHomeEnd','/satisfactionSurveyForPcHome','/audio'] // 不重定向白名单
router.beforeEach((to, from, next) => {
  NProgress.start()



  // 页面埋点结束


  if (getToken()) {

  // 页面埋点 开始
  var params = {
    module:from.meta.title,
    page:to.meta.pvTitle || to.meta.title,
    resourceId:to.query[to.meta.resourceId||'id']
  }
  store.dispatch('SetTrack', { params}).then(() => { // 生成可访问的路由表

  })
  pvTrack(params)
    if (to.path === '/login') {
      // if (Boolean(Number(localStorage.getItem('audiStarTestSwith'))) ) {
      //   next({ path: '/audiStarGame' })
      // } else {
      //   next({ path: '/index' })
      // }
      next({ path: '/index' })
      NProgress.done()
    }

    else {


      if (to.path === '/index') {
        // if (Boolean(Number(localStorage.getItem('audiStarTestSwith'))) ) {
        //   next({ path: '/audiStarGame' })
        // }
      }

      // store.dispatch('GetPersonInfo', { }).then(() => { // 获取个人徽章等信息
      //   next()
      // })

      if(store.getters.userInfo.id){
        next()
        store.dispatch('GetMenuList', { }).then(() => { // 生成可访问的路由表
          next()
        })
      }else{

        store.dispatch('GetInfo').then(res => { // 拉取用户信息
          // localStorage.setItem('userInfo', JSON.stringify(res.data));
          store.dispatch('GetMenuList', { }).then(() => { // 生成可访问的路由表
            next()
          })
          // next()
        }).catch((err) => {
          store.dispatch('FedLogOut').then(() => {
            Message.error(err || 'Verification failed, please login again')
            next({ path: '/login' })
          })
        })
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {

          next()
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach(to => {
  if(to.meta && to.meta.title){
    if(to.path=="/satisfactionSurveyHomeEnd" || to.path=="/satisfactionSurveyHome" ||  to.path=='satisfactionSurveyForPcHome' || to.path=="/satisfactionSurvey" || to.path=="/specialTest/examinationCopy"){
      document.title = `${to.meta.title}`;
    }else{
      document.title = `${to.meta.title}-${config.systemName}`;
    }
  }else{
    document.title = `404-${config.systemName}`;
  }
  NProgress.done() // 结束Progress
})
