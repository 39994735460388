
export default {
  routes: [
    {

      path: "/course",
      name: "course",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta:{title:'公共课程'},
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/courseMarket.vue"),
    },
    {
      path: "/courseMarket", // 旧页面
      name: "courseMarket",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta:{title:'课程超市'},
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/courseList.vue"),
    },
    {
      path: "/courseDetail",
      name: "courseDetail",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta:{title:'课程详情'},
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/newPage/newDetail.vue"),
    },
    {
      path: "/courseNewDetail",  // 旧页面
      name: "courseNewDetail",
      meta:{title:'课程详情'},
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/courseIntroduce.vue"),
    },

    {
      path: "/courseNewStudy",  // 旧页面
      name: "courseNewStudy",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta:{title:'课程详情'},
      component: () =>
          import(/* webpackChunkName: "courseList" */ "@/views/course/courseStudy.vue"),
    },
    {
      path: "/courseStudy",
      name: "courseStudy",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta:{title:'课程学习',resourceId:'coursewareId'},
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/newPage/courseStudy.vue"),
    },
    {
      path: "/compulsoryCourse",
      name: "compulsoryCourse",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta:{title:'必修课程'},
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/compulsoryCourse.vue"),
    },
    {
      path: "/newDealerPackage",
      name: "newDealerPackage",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta:{title:'必修课程'},
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/newDealerPackage.vue"),
    },
    {
      path: "/recommend",
      name: "recommend",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: { title: '热门推荐' },
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/recommend.vue"),
    },
    {
      path: "/postCourses",
      name: "postCourses",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: { title: '岗位课程' },
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/postCourses.vue"),
    },
    {
      path: "/myNotes",
      name: "myNotes",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: { title: '我的笔记' },
      component: () =>
        import(/* webpackChunkName: "courseList" */ "@/views/course/newPage/myNotes.vue"),
    },


  ],
  // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
  // path 的值与 iframeRoutePath 相等
  iframes: []
};
