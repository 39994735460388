
export default {
    routes: [
      {
        path: "/myHonor",
        name: "myHonor",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta:{title:'我的荣誉'},
        component: () =>
          import(/* webpackChunkName: "verbalTrick" */ "@/views/myHonor/index.vue"),
        },
      {
        path: "/allMessage",
        name: "allMessage",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta:{title:'我的荣誉'},
        component: () =>
          import(/* webpackChunkName: "verbalTrick" */ "@/views/myHonor/allMassage.vue"),
        },
      {
        path: "/allCertificate",
        name: "allCertificate",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta:{title:'我的荣誉'},
        component: () =>
          import(/* webpackChunkName: "verbalTrick" */ "@/views/myHonor/allCertificate.vue"),
        },
      {
        path: "/allHonor",
        name: "allHonor",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta:{title:'我的荣誉'},
        component: () =>
          import(/* webpackChunkName: "verbalTrick" */ "@/views/myHonor/allHonor.vue"),
        },
      {
        path: "/allRanking",
        name: "allRanking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta:{title:'我的荣誉'},
        component: () =>
          import(/* webpackChunkName: "verbalTrick" */ "@/views/myHonor/allRanking.vue"),
        },
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
