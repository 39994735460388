import request from '@/utils/request'

// 查询积分流水列表
export function selectHonorIntegralFlowList(params) {
  return request({
    url: '/aitp-master/portal/honorIntegral/selectHonorIntegralFlowList',
    mtf: 'et',
    params: params
  })
}

// 查询我的证书列表
export function selectHonorCertList(params) {
  return request({
    url: '/aitp-master/portal/honorCert/selectHonorCertList',
    mtf: 'et',
    params: params
  })
}

// 查询我的徽章列表
export function selectHonorMedalList(params) {
  return request({
    url: '/aitp-master/portal/honorMedal/selectHonorMedalList',
    mtf: 'et',
    params: params
  })
}

// 佩戴徽章
export function wareMedal(params) {
  return request({
    url: '/aitp-master/portal/honorMedal/wareMedal',
    mtf: 'et',
    params: params
  })
}

// 我的荣誉用户数据
export function myHonorUserData(params) {
  return request({
    url: '/aitp-master/portal/honorMedal/myHonorUserData',
    mtf: 'et',
    params: params
  })
}

// 我的荣誉首页-徽章数据5个
export function myHonorMedalList(params) {
  return request({
    url: '/aitp-master/portal/honorMedal/myHonorMedalList',
    mtf: 'et',
    params: params
  })
}

//
export function saveUserIntegral(params) {
  return request({
    url: '/aitp-master/portal/honorIntegral/saveUserIntegral',
    mtf: 'et',
    params: params
  })
}

// 查询全国、大区、经销商积分-课时数排行接口
export function getRanking(params) {
  return request({
    url: '/aitp-master/portal/honorMedal/getRanking',
    mtf: 'et',
    params: params
  })
}

// 查询积分规则文案表（pc展示）
export function selectIntegralRuleList(params) {
  return request({
    url: '/aitp-master/portal/honorIntegralRulePc/selectIntegralRuleList',
    mtf: 'et',
    params: params
  })
}

// 查询新获得的徽章
export function selectNewMedal(params) {
  return request({
    url: '/aitp-master/portal/home/selectNewMedal',
    mtf: 'et',
    params: params
  })
}
